export const siteData = {
  "name": "CONTRIXO",
  "address": "79 CHEMIN DES SOURCES,",
  "postCode": "74550",
  "city": "orcier",
  "phone": "0306859705",
  "url": "https://contrixo.store",
  "urlSub": "contrixo.store",
  "price": "price_ase5EhnWeTQVvOchGJSnwFMS4tQ3pU7q",
  "pk": "pk_live_M1DfJEayAu3cBNW3auPpMqBqCf7lPvfv",
  "sk": "sk_live_hy0iUnAh6uwIHQ9FunhDadrAWtKIQVDU"
};